<template>
  <div>
    <b-row>
      <b-col
        xl="12"
      >

    <Nonpay-side-bar-edit
      :isEditSidebarActive="isEditSidebarActive"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :payment-methods="paymentMethods"
      :transaction="selected"
      @clicked="one"
    />
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
      </b-col>
      <b-col
        cols="12"
        md="2"
      >

    </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            class="mb-1"
            @click="exportToExcel()"
          >
            <span class="text-nowrap">Export</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      striped
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(status_number)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>
    <b-row>
      <b-col class="mt-1">
        Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          size="md"
          class="my-0"
          @change="handleChangePage"
        />
      </b-col>
    </b-row>
  </b-col>
</b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Statistics from './Nonpay-statistics.vue'
import vSelect from 'vue-select'
import NonpaySideBarEdit from './NonpaySideBarEdit.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import {
  BTable, BAvatar, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,BModal, VBModal} from 'bootstrap-vue'
export default {
  components: {
    NonpaySideBarEdit,
    moment,
    Statistics,
    flatPickr,
    BTable,
    BModal, VBModal,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rangeDate: null,
      isEditSidebarActive: false,
      paymentMethods:[],
      stats:null,
      test_string : null,
      user_id : 'all',
      staffOptions : [],
      headerTitle: ['Date', 'Visa','Master', 'FPS', 'Payme', 'Cash','Daily Total'],
      headerVal: ['date', 'visa','master', 'fps', 'payme', 'cash','total'],
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      centerOptions: [],
      isAddNewMemberSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'code', label: '#' }, { key: 'amount', label: 'Amount' },{ key: 'member_code', label: 'Client' }, { key: 'nonpay_amount', label: 'Non-Pay' }, { key: 'created_at', label: 'Date' }],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Active', 2: 'Disabled', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    user_id : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    rangeDate : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },

    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
    this.get_payment_method()
    this.get_stats()
  },
  methods: {
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
    })
  },
    one() {
      this.isEditSidebarActive = false
      this.selected = []
      this.get_data()
      this.get_stats()
    },
    exportToExcel() {
      console.log(this.rangeDate)
      import('@/@core/Export2Excel').then(excel => {
        const list = this.items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'daily_sales',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        })
      )
    },
    get_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/report/nonpay/`)
        .then(res => {
          this.items = res.data
        })
    },
    get_stats() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/report/nonpay/stats/`)
        .then(res => {
          this.stats = res.data
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      console.log(this.selected)
      this.$http.get(process.env.VUE_APP_API_BASE+'/transaction/'+this.selected.id+'/')
      .then(res => {
        this.selected = res.data
        this.isEditSidebarActive = true
      })
    },
    close() {
      this.iframe_src = '//www.cloudfit.pro/invoice.php'
      this.$refs['my-modal'].hide()
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
